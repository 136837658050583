/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import "@fontsource/poppins/400.css"
import "@fontsource/poppins/500.css"
import "@fontsource/poppins/600.css"
import "@fontsource/poppins/700.css"
import "@fontsource/young-serif/400.css"

import "./src/styles/tailwind.css"

import { labels as recipeLabels } from "./src/constants/recipe.constants"
import { defaultLanguage } from "./src/constants/locale.constants"

export const shouldUpdateScroll = ({ prevRouterProps, routerProps: { location } }) => {
    const currentPathname = location.pathname.replace(`/${defaultLanguage}`, "")
    const prevPathname = prevRouterProps?.location.pathname.replace(`/${defaultLanguage}`, "")
    const isRecetasPage =
        currentPathname === recipeLabels.slug &&
        (prevPathname === recipeLabels.slug || prevPathname === `${recipeLabels.slug}/`)
    if (isRecetasPage) {
        return false
    }
    return true
}
